<template>
  <v-container class="text-center pa-5">
    <!-- Icono Superior -->
    <v-row justify="center" class="my-8">
      <v-col cols="12">
        <v-img
          :src="require('@/assets/inventario.png')"
          contain
          max-height="170"
          alt="Icono de productos"
          class="mb-3"
        ></v-img>
        <h2 class="font-weight-bold primary--text">
          Agrega productos a tu inventario
        </h2>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-6">
      <!-- Registrar producto -->
      <v-col cols="12" md="8" class="mb-4">
        <v-card
          outlined
          class="pa-4 text-center"
          elevation="2"
          @click="redirectToBarcodeScanner"
        >
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12" md="12" class="d-flex align-center">
              <v-icon size="36" class="mr-5" color="black">
                mdi-barcode-scan
              </v-icon>
              <div>
                <h3 class="font-weight-bold text-start">
                  Registra un producto
                </h3>
                <p class="text-body-2 text-start">
                  Agrega productos, escanea el código de barras o ingresa su
                  información
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Importar productos -->
      <v-col cols="12" md="8">
        <v-card outlined class="pa-4 text-center" elevation="2">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12" md="12" class="d-flex align-center">
              <v-icon size="36" class="mr-5" color="black">
                mdi-file-import-outline
              </v-icon>
              <div>
                <h3 class="font-weight-bold text-start">Importa productos</h3>
                <p class="text-body-2 text-start">
                  Completa nuestra plantilla con tus productos y cárgalos de
                  forma masiva
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Botón de asistencia -->
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-btn
          rounded
          block
          outlined
          color="primary"
          class="py-4 border-3"
          style="border-width: 3px"
          @click="openPatAssistance"
        >
          <v-icon left>mdi-help-circle-outline</v-icon>
          Asistencia PAT
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      modalOpen: false, // Asegúrate de que esta propiedad exista en data
    };
  },
  methods: {
    openPatAssistance() {
      console.log("Abriendo asistencia PAT...");
    },
    redirectToBarcodeScanner() {
      this.$router.push({ name: "barcode-scanner" });
    },
  },
};
</script>
